<template>
  <v-dialog
    v-model="contactDetail"
    max-width="600"
    @click:outside="close()"
  >
    <v-card
      max-width="600"
      class="mx-auto"
    >
      <form>
        <div
          class="card-header"
          dark
        >
          <v-row class="fill-height">
            <div class="action-buttons">
              <v-btn
                v-if="!editMode"
                @click="editMode = true"
                dark
                icon
                class="mr-2"
              >
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                dark
                icon
              >
                <v-icon @click="close()">mdi-close</v-icon>
              </v-btn>
            </div>

            <v-spacer></v-spacer>

            <v-card-title
              class="white--text px-8"
            >
              <div
                v-if="!editMode"
                class="display-3"
              >
                {{ activeContact.sexe ? titreItems.find(item => item.sexe === activeContact.sexe).titre : '' }} {{ activeContact.prenom }} {{ activeContact.nom }}
              </div>
              <v-row
                v-if="editMode"
              >
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-select
                    label="Titre"
                    v-model="activeContact.sexe"
                    :items="titreItems"
                    item-text="titre"
                    item-value="sexe"
                    dark
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-text-field
                    label="Prénom"
                    v-model="activeContact.prenom"
                    dark
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-text-field
                    label="Nom"
                    v-model="activeContact.nom"
                    dark
                  />
                </v-col>
              </v-row>
            </v-card-title>
          </v-row>
        </div>

        <!-- list read mode -->
        <v-card-text
          v-if="!editMode"
        >
          <v-list>
            <v-list-item v-if="activeContact.telephonePro">
              <v-list-item-icon>
                <v-icon color="indigo">
                  mdi-phone
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ formatPhone(activeContact.telephonePro) }}</v-list-item-title>
                <v-list-item-subtitle>Professionnel</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="activeContact.telephonePerso">
              <v-list-item-icon>
                <v-icon
                  v-if="!activeContact.telephonePro"
                  color="indigo"
                >
                  mdi-phone
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ formatPhone(activeContact.telephonePerso) }}</v-list-item-title>
                <v-list-item-subtitle>Personnel</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider
              v-if="activeContact.email"
              inset
            />

            <v-list-item>
              <v-list-item-icon>
                <v-icon color="indigo">
                  mdi-email
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ activeContact.email }}</v-list-item-title>
                <v-list-item-subtitle>E-mail</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider
              v-if="activeContact.adresse"
              inset
            />

            <v-list-item v-if="activeContact.adresse &&  Object.keys(activeContact.adresse).length !== 0">
              <v-list-item-icon>
                <v-icon color="indigo">
                  mdi-map-marker
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title style="white-space: pre-line;">{{ activeContact.adresse.adressePostale }}</v-list-item-title>
                <v-list-item-title>{{ activeContact.adresse.codePostal }} {{ activeContact.adresse.ville }}{{ activeContact.adresse.pays }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider
              v-if="activeContact.commentaire"
              inset
            />

            <v-list-item v-if="activeContact.commentaire">
              <v-list-item-icon>
                <v-icon color="indigo">
                  mdi-comment-processing
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-subtitle>{{ activeContact.commentaire }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <!-- list edit mode -->
        <v-card-text
          v-if="editMode"
          class="px-8"
        >
          <v-row>
            <v-col
              cols="12"
            >
              <div class="flex space-around">
                <v-checkbox
                  v-model="activeContact.types"
                  value="ADMINISTRATIF"
                  label="Administratif"
                  hide-details
                />
                <v-checkbox
                  v-model="activeContact.types"
                  value="COMMERCIAL"
                  label="Commercial"
                  hide-details
                />
                <v-checkbox
                  v-model="activeContact.types"
                  value="FACTURATION"
                  label="Facturation"
                  hide-details
                />
              </div>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                label="Professionnel"
                class="purple-input"
                type="tel"
                prepend-icon="mdi-phone"
                v-model="activeContact.telephonePro"
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                label="Personnel"
                class="purple-input"
                type="tel"
                prepend-icon="mdi-phone"
                v-model="activeContact.telephonePerso"
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                label="E-mail"
                class="purple-input"
                type="email"
                prepend-icon="mdi-email"
                v-model="activeContact.email"
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
            >
              <v-textarea
                label="Adresse postale"
                class="purple-input"
                prepend-icon="mdi-map-marker"
                v-model="activeContact.adresse.adressePostale"
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                label="Code postal"
                class="purple-input"
                v-model="activeContact.adresse.codePostal"
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              md="5"
            >
              <v-text-field
                label="Ville"
                class="purple-input"
                v-model="activeContact.adresse.ville"
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                label="Pays"
                class="purple-input"
                v-model="activeContact.adresse.pays"
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                label="Commentaire"
                class="purple-input"
                prepend-icon="mdi-comment-processing"
                v-model="activeContact.commentaire"
                hide-details
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          class="flex-end mr-2"
        >
          <v-btn
            v-if="editMode"
            @click="deleteContact()"
            class="mr-2"
            small
            color="red"
          >
            <v-icon
              class="mr-2"
            >
              mdi-delete
            </v-icon>
            Supprimer le contact
          </v-btn>
          <v-btn
            v-if="editMode"
            @click="saveUpdates()"
            small
            class="mr-2"
            color="success"
          >
            <v-icon
              class="mr-2"
            >
              mdi-content-save
            </v-icon>
            Sauvegarder le contact
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>

  import { mapMutations } from 'vuex';

  export default {

    data() {
      return {
        editMode: false,
        contactDetail: false,
        activeContact: { },
        client: {},
        nature: null,
        titreItems: [
          {
            titre: 'M.',
            sexe: 'm',
          },
          {
            titre: 'Mme',
            sexe: 'f',
          },
        ],
      }
    },

    methods: {
      create(entreprise, nature) {
        this.entreprise = entreprise;
        this.nature = nature;

        this.activeContact = {
          nom: '',
          prenom: '',
          sexe: null,
          telephonePro: '',
          telephonePerso: '',
          email: '',
          adresse: {},
          commentaire: '',
          types: [],
        };
        this.editMode = true;
        this.contactDetail = true;
      },

      show(item, entreprise) {
        this.activeContact = item;
        this.entreprise = entreprise;
        if (this.activeContact.adresse == null) {
          this.activeContact.adresse = {};
        }
        this.contactDetail = true;
      },

      saveUpdates() {
        if (this.activeContact.adresse == null) {
          this.activeContact.adresse = {};
        }

        if (this.activeContact.id) {
          // edit mode
          this.axios.put('/contacts/' + this.activeContact.id, this.activeContact)
            .then((res) => {
              this.editMode = false;
              this.setSnack({ text: 'Le contact a bien été modifié', type: 'success' });
            })
            .catch(e => {
              this.setSnack({ text: 'Erreur lors de la modification du contact', type: 'warn' });
            })
        } else {
          // create mode
          this.axios.post('/contacts', this.activeContact)
            .then((res) => {
              this.activeContact.id = res.data.id;
              this.entreprise.contacts.push(this.activeContact);
              if (this.nature === 'CLIENT') {
                return this.axios.put('/clients/' + this.entreprise.id, this.entreprise);
              } else if (this.nature === 'FINANCEUR') {
                return this.axios.put('/financeurs/' + this.entreprise.id, this.entreprise);
              } else if (this.nature === 'SOUSTRAITANT') {
                return this.axios.put('/entreprisesSousTraitantes/' + this.entreprise.id, this.entreprise);
              } else {
                throw new Error('Type d\'entreprise (nature) non reconnu');
              }
            })
            .then((res) => {
              this.editMode = false;
              this.setSnack({ text: 'Le contact a bien été créé', type: 'success' });
            })
            .catch(e => {
              this.setSnack({ text: 'Erreur lors de la création du contact', type: 'warn' });
            })
        }
      },

      close() {
        this.contactDetail = false;
        this.editMode = false;
      },

      deleteContact() {
        this.entreprise.contacts.splice(this.client.contacts.indexOf(this.activeContact));

        this.axios.patch('/clients/' + this.client.id + '/updateContacts', this.client.contacts)
          .then((res) => {
            return this.axios.delete('/contacts/' + this.activeContact.id);
          })
          .then((res) => {
            this.close();
            this.setSnack({ text: 'Le contact a bien été supprimé', type: 'success' });
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la suppression du contact', type: 'warn' });
          });
      },

      formatPhone(number) {
        return number.toString().replace(/\B(?=(\d{2})+(?!\d))/g, ' ');
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },

  }
</script>

<style scoped>
  .action-buttons {
    text-align: right;
    width: 100%;
    padding: 12px 24px;
  }

  .card-header {
    height: 200px;
    background-color: #3f51b5;
  }

  .flex {
    display: flex;
  }

  .space-around {
    justify-content: space-around;
  }

  .flex-end {
    justify-content: flex-end;
  }

  >>> .v-dialog {
    border-radius: 30px;
  }

  .v-list-item__title {
    padding-bottom: 2px;
  }
</style>
